<template>
  <div class="errors-list">
    <!-- Content -->

    <h1>
      CBT Thinking Errors
    </h1>

    <h3 style="font-size:15px;margin-top:30px;" class="links-content">
      
      Cognitive Behavioral Therapy (CBT) is a scientific, well-tested, most proven, and most effective logical approach for challenging our distorted thoughts and transforming them from intrusive and unhelpful into rational and beneficial.

      <br><br>

      The irrational and often negative thoughts are common to different people. These thoughts are classified into different types, named together as cognitive distortions, also known as thinking errors or <a href="https://twitter.com/thinkingbugs/status/1182119140267569154" target="_blank">thinking bugs</a>.

      By deconstructing and changing our irrational thoughts, we can greatly improve our life quality, performance, general well-being and solve many psychological issues, such as anxiety, panic attacks, depression, OCD, PTSD, ADHD, and similar.

      <br><br>
      The list of classical cognitive distortions is presented below. These thinking bugs can be spotted in our thought patterns and fixed using the <a href="/cbt-app" target="_blank">CBT App</a>.

      <br><br>
      
    </h3>

    <div>
      
      <div class="te-div">
        <router-link to="/all-or-nothing-thinking">
          <div class="icon-div">
            AoN
          </div>
        </router-link>
        <div class="icon-text">
          <router-link to="/all-or-nothing-thinking" class="link bug-title">
            All or Nothing Thinking
          </router-link>
          <br>
          <div style="height:5px;"></div>
          <span class="small">
            You look at things in absolute, black-and-white categories.
          </span>
        </div>
      </div>
      
      <div class="te-div">
        <router-link to="/magnification-or-minimization">
          <div class="icon-div">
            MoM
          </div>
        </router-link>
        <div class="icon-text">
          <router-link to="/magnification-or-minimization" class="link bug-title">
            Magnification or Minimization
          </router-link>
          <br>
          <div style="height:5px;"></div>
          <span class="small">
            You blow things way out of proportion or shrink them.
          </span>
        </div>
      </div>

      <div class="te-div">
        <router-link to="/mental-filter">
          <div class="icon-div">
            MF
          </div>
        </router-link>
        <div class="icon-text">
          <router-link to="/mental-filter" class="link bug-title">
            Mental Filter
          </router-link>
          <br>
          <div style="height:5px;"></div>
          <span class="small">
            You dwell on the negatives and ignore the positives.
          </span>
        </div>
      </div>

      <div class="te-div">
        <router-link to="/discounting-positives">
          <div class="icon-div">
            DP
          </div>
        </router-link>
        <div class="icon-text">
          <router-link to="/discounting-positives" class="link bug-title">
            Discounting Positives
          </router-link>
          <br>
          <div style="height:5px;"></div>
          <span class="small">
            You insist your positive qualities don't count.
          </span>
        </div>
      </div>

      <div class="te-div">
        <router-link to="/labeling">
          <div class="icon-div">
            Lab
          </div>
        </router-link>
        <div class="icon-text">
          <router-link to="/labeling" class="link bug-title">
            Labeling
          </router-link>
          <br>
          <div style="height:5px;"></div>
          <span class="small">
            Instead of saying, “I made a mistake,” you tell yourself, “I'm a jerk” or “I'm a loser.”
          </span>
        </div>
      </div>

      <div class="te-div">
        <router-link to="/personalization">
          <div class="icon-div">
            Per
          </div>
        </router-link>  
        <div class="icon-text">
          <router-link to="/personalization" class="link bug-title">
            Personalization
          </router-link>
          <br>
          <div style="height:5px;"></div>
          <span class="small">
            You consider negative or irrelevant events as having something to do with you.
          </span>
        </div>
      </div>

      <div class="te-div">
        <router-link to="/fortune-telling">
          <div class="icon-div">
            FT
          </div>
        </router-link>
          <div class="icon-text">
            <router-link to="/fortune-telling" class="link bug-title">
              Fortune Telling
            </router-link>
            <br>
            <div style="height:5px;"></div>
            <span class="small" style="text-decoration:none!important;">
              You jump to conclusions not warranted by the facts.
            </span>
          </div>
      </div>

      <div class="te-div">
        <router-link to="/mind-reading">
          <div class="icon-div">
            MR
          </div>
        </router-link>
        <div class="icon-text">
          <router-link to="/mind-reading" class="link bug-title">
            Mind Reading
          </router-link>
          <br>
          <div style="height:5px;"></div>
          <span class="small">
            You assume that people are reacting negatively to you.
          </span>
        </div>
      </div>


      <div class="te-div">
        <router-link to="/overgeneralization">
          <div class="icon-div">
            Ovg
          </div>
        </router-link>
        <div class="icon-text">
          <router-link to="/overgeneralization" class="link bug-title">
            Overgeneralization
          </router-link>
          <br>
          <div style="height:5px;"></div>
          <span class="small">
            You view a single negative event as a never-ending pattern of defeat.
          </span>
        </div>
      </div>

      <div class="te-div">
        <router-link to="/should-statements">
          <div class="icon-div">
            SS
          </div>
        </router-link>
        <div class="icon-text">
          <router-link to="/should-statements" class="link bug-title">
            Should Statements
          </router-link>
          <br>
          <div style="height:5px;"></div>
          <span class="small">
            You use “shoulds,” “shouldn'ts,” “musts,” “oughts,” and “have tos.”
          </span>
        </div>
      </div>

      <div class="te-div">
        <router-link to="/self-blame">
          <div class="icon-div">
            SB
          </div>
        </router-link>
        <div class="icon-text">
          <router-link to="/self-blame" class="link bug-title">
            Self Blame
          </router-link>
          <br>
          <div style="height:5px;"></div>
          <span class="small">
            You blame yourself for something you weren't entirely responsible for.
          </span>
        </div>
      </div>

      <div class="te-div">
        <router-link to="/other-blame">
          <div class="icon-div">
            OB
          </div>
        </router-link>
        <div class="icon-text">
          <router-link to="/other-blame" class="link bug-title">
            Other Blame
          </router-link>
          <br>
          <div style="height:5px;"></div>
          <span class="small">
            You blame others and overlook ways you contributed to the problem.
          </span>
        </div>
      </div>
      
      <div class="te-div">
        <router-link to="/emotional-reasoning">
          <div class="icon-div">
            ER
          </div>
        </router-link>
        <div class="icon-text">
          <router-link to="/emotional-reasoning" class="link bug-title">
            Emotional Reasoning
          </router-link>
          <br>
          <div style="height:5px;"></div>
          <span class="small">
            You reason from your feelings: “I feel like an idiot, so I must be one.”
          </span>
        </div>
      </div>

    </div>



    <div style="clear:both;height:20px;"></div>

    <h1>
      More info
    </h1>

    <div style="height:20px;"></div>

    <p>The Thinking Bugs website is a resource for those looking to improve their mental health and well-being through the use of Cognitive Behavioral Therapy (CBT). On the site, visitors can find descriptions and examples of the 13 classical thinking errors, also known as cognitive distortions. These distortions are common patterns of thinking that can lead to negative emotions and behaviors.</p>

    <p>To help users identify and overcome negative thought patterns, the website also offers a privacy-focused CBT journaling app. This app provides a tool for journaling and reframing thoughts in a more positive and healthy way. By using the app, users can learn to recognize thinking bugs in their thoughts and take steps to rewrite and reframe them. Whether you are looking to learn more about cognitive distortions or want to work on improving your mental health through CBT, the Thinking Bugs website and journaling app are valuable resources.</p>

    <p>At Thinking Bugs, we utilize the garden and computer metaphors to better understand how these "bugs" can affect the mind and its functioning.</p>

    <p>In the garden metaphor, bugs refer to negative thoughts and behaviors that can take over and dominate the mind if it is not properly cared for. These "bugs" can be thought of as weeds that grow and spread in the garden of the mind, hindering the growth of positive thoughts and behaviors.</p>

    <p>Similarly, in the computer metaphor, bugs refer to errors or glitches in the system that can cause problems and hinder the performance of the computer. In the mind, these "bugs" can be negative thought patterns or thinking errors that disrupt and hinder optimal functioning. Just as a computer requires regular maintenance to fix bugs and ensure proper functioning, the mind also needs care and attention to identify and address negative thought patterns.</p>

    <p>If you are new to CBT, we recommend seeking additional resources before using our CBT journaling app. While our website provides helpful information, it is not intended to fully explain the practice of CBT.</p>

    <div style="height:25px;"></div>

    <p><b>DISCLAIMER</b></p>

    <p style="text-transform:uppercase;">
      The content on the Thinking Bugs website has been partially generated by the ChatGPT AI engine and subsequently reviewed by a human editor to ensure accuracy.
      <br><br>
      The ideas and suggestions contained on this website are not intended as a substitute for consulting with your physician. All matters regarding your health require medical supervision.
    </p>

    <div style="height:25px;"></div>

  </div>
</template>


<script>
export default {

  components: {

  },

  data: function () {
    return {
      
    }
  },

};
</script>


<style>

</style>